import * as React from "react"
import { useEffect } from "react"
import Layout from "../../../components/layout"
import Seo from "../../../components/seo"
import { getNoveltieAction,getNoveltiesAction } from "../../../redux/noveltiesDucks"
import { graphql, useStaticQuery } from 'gatsby';
import {connect} from "react-redux"
//Components

const ClientSideOnlyLazy = React.lazy(() =>
import('../../../components/Novedad/main')
)


const IndexPage = (props) => {
  
  const {id} = props
  const {slug} = props
  const {dispatch} = props
  // const {serverData} = props
  
  const { realEstate } = useStaticQuery(graphql`
    query {
        realEstate {
            name
            env { 
              APISERVER
              TENANTID
            }
        }
  }`)

  // console.log(serverData)

  const isSSR = typeof window === "undefined"

  useEffect(() => {
    const { env } = realEstate
    if(id){
      dispatch(getNoveltieAction(env,id))
      dispatch(getNoveltiesAction(env,true))
    }
  },[id])
  return(
    <Layout>
      {/* <Seo 
        title={serverData?.data.post.title}
        description={serverData?.data.post.excerpt + " - " + serverData?.data.post.title}
        image={process.env.GATSBY_SERVER_URL + serverData?.data.post.image}
        /> */}
      {!isSSR && (
          <React.Suspense fallback={<div />}>
            <ClientSideOnlyLazy />
          </React.Suspense>
        )}
    </Layout>
  )
}

export default connect(state => ({
}),null)(IndexPage);

// export async function getServerData(context) {
//   try {
//     const res = await fetch(`https://dev.mediacore.com.ar/api/v1/posts/${context.params.id}`,{headers: {'Authorization': 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIxIiwianRpIjoiODMyZGJlYjQ2YjA3Yzk0OWFmYjFlZjcyMjdmOGUyMGUwZDU4NWRlMTQwMjRiZjYwNDI3ODZjMDc4MGYwZDdiZTNlMjNiOGYxNDMxMmMwZWUiLCJpYXQiOjE2MzUxODA1MTMsIm5iZiI6MTYzNTE4MDUxMywiZXhwIjoxNjY2NzE2NTEzLCJzdWIiOiIyOSIsInNjb3BlcyI6W119.G7y15Pvy53Y3USzw6BRTh38GmzR2YchXBum0WRwzbsy68TIDhIQ5IulSEG5RBUAd3yddBn6K4sb4fZfvqkfdKuBZua1dxzpC8ozNbfuPFtt8HR52Qy9avCdVY-MgyFukY2u7FmuMdBycLt2qQLfjrKNJtBfeS7DxDwmZunOK5z3QIXc3ekjfLhJaJpEntPT4C9NzfMpjkGkdmsxBXYV7M9jYgBDiB4UiMw85OcmqP_u7F2mqw9WMnD7r-tyusc2_sQxT8B_lzuVs7gP5Se1HindRBuC2kSOYTLguH0ymBfNeS7p6eRbWN8HkkwGrdWcvNvklq9U58C9kzBaiTS9W3gBjuuww3r4dOVYfDuHYvBdgkFy1CXrJmPYGZawyPaGhRLsE6UDBRmdbcuGu6ILjfvT0hJ-zkkBniRysiIPITA0COCeXjg1XdD70euuaVpv9RT0EU8LzM2G__i_Z-SX_Beg_40nrGXfMYgdgnRnAgpcYFaeK2VZBmsL9XWsek8MP_tLvRhOQSTkFMDEc6uviN6YRjzcPWm8GMAte-Bi9vv31C8lFosjzD07lUj-8jWAZqGdm23yquDfth9Njt2e4UNrSvxU5sJXrjpKeHy8RYs3OE-s6cMwQfNnmxVcc6o4S0TZEHfqFOYCewotvjFm1Ea47b-7oKhT-jbVo_2aXDVg'}})
//     if (!res.ok) {
//       throw new Error(`Response failed`)
//     }
//     return {
//       props: await res.json(),
//     }
//   } catch (error) {
//     return {
//       status: 500,
//       headers: {},
//       props: {}
//     }
//   }
// }